<template>
  <analytics />
</template>

<script>
import Analytics from './Analytics';

export default {
  components: {
    Analytics
  }
};
</script>
