<template>
  <vue-highcharts v-if="chartOptions" type="chart" :options="chartOptions" />
</template>
<script>
import VueHighcharts from 'vue3-highcharts';
import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import exporting from 'highcharts/modules/exporting';
import exportData from 'highcharts/modules/export-data';
import accessibility from 'highcharts/modules/accessibility';

highchartsMore(Highcharts);
exporting(Highcharts);
exportData(Highcharts);
accessibility(Highcharts);

export default {
  components: { VueHighcharts },
  props: {
    options: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  data() {
    return {
      chartOptions: null,
    };
  },
  mounted() {
    this.setChartOptions();
  },

  mounted() {
    this.setChartOptions();
  },

  methods: {
    setChartOptions() {
      const { data, labels, xlabelString, ylabelString } = this.options;
      this.chartOptions = {
        chart: {
          zoomType: 'xy',
          height: '70%',
          scrollablePlotArea: {
            minHeight: labels.length * 100,
            scrollPositionY: 0,
          },
        },
        title: {
          text: '', //title,
        },
        xAxis: {
          categories: labels,
          crosshair: true,
          title: {
            text: xlabelString,
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: ylabelString,
          },
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          formatter() {
            return `</br><span>Start Time:</span> <b>${this.point.startTime}</b>
                    </br><span>End Time:</span> <b>${this.point.endTime}</b>
                  </br><span>Average Background Time:</span> <b>${this.point.y}s</b>`;
          },
        },
        plotOptions: {
          bar: {
            pointPadding: 0.6,
            borderWidth: 1,
            dataLabels: {
              enabled: true,
              formatter: function() {
                return this.y + 's';
              },
            },
          },
          series: {
            pointWidth: 12,
          },
        },
        series: data,
      };
    },
  },
};
</script>
<style></style>
