<template>
  <vue-highcharts v-if="chartOptions" type="chart" :options="chartOptions" />
</template>
<script>
import VueHighcharts from 'vue3-highcharts';
import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import exporting from 'highcharts/modules/exporting';
import exportData from 'highcharts/modules/export-data';
import accessibility from 'highcharts/modules/accessibility';

highchartsMore(Highcharts);
exporting(Highcharts);
exportData(Highcharts);
accessibility(Highcharts);

export default {
  components: { VueHighcharts },
  props: {
    options: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  data() {
    return {
      chartOptions: null,
    };
  },

  mounted() {
    this.setChartOptions();
  },

  methods: {
    setChartOptions() {
      const { data, labels, xlabelString, ylabelString } = this.options;
      this.chartOptions = {
        chart: {
          zoomType: 'xy',
          height: '70%',
          scrollablePlotArea: {
            minHeight: labels.length * 60,
            scrollPositionY: 0,
          },
          style: {
            padding: '0.5em',
          },
        },
        credits: {
          enabled: false,
        },
        title: {
          text: '', //title,
        },
        legend: {
          enabled: false,
        },
        xAxis: {
          categories: labels,
          title: {
            text: xlabelString,
          },
        },

        yAxis: {
          title: {
            text: ylabelString,
          },
        },
        tooltip: {
          formatter: function() {
            const { x, y } = this;
            return `<b>${x}</b> missed in <b>${y}</b> cycle(s).`;
          },
        },

        series: [
          {
            type: 'bar',
            data: data,
          },
        ],
      };
    },
  },
};
</script>
