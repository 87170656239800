<template>
  <a-modal
    id="analytics-page-video-modal"
    v-model:visible="showVideoModal"
    centered
    :title="videoToPlay?.fileName"
    destroy-on-close
    width="100%"
    wrap-class-name="full-modal"
    :body-style="{ padding: '10px 12px' }"
    :footer="null"
    @cancel="handleCloseVideoModal"
  >
    <recorded-inference
      :is-fetching-video-url="isFetchingVideoUrl"
      :video="videoToPlay"
      :steps-list="stepsList"
      :comments="videoToPlay?.comments"
      @updateVideo="updateVideoRecord"
    />
  </a-modal>

  <a-row :gutter="[16, 16]">
    <a-col span="24">
      <Filters
        ref="filtersComponent"
        :is-no-data-available="isNoDataAvailable"
        :is-analytics="true"
        @change="handleGetData"
      />
    </a-col>
    <a-col span="24" class="text-right">
      <a-space>
        <a-button
          v-if="!isNoDataAvailable && !showCompareAnalytics"
          type="primary"
          :disabled="isFetchingCycles"
          @click="exportCyclesCSV"
        >
          Export cycles CSV
          <template #icon>
            <export-outlined />
          </template>
        </a-button>

        <a-button
          v-if="!isNoDataAvailable && !showCompareAnalytics"
          type="primary"
          class="ml-auto"
          :disabled="isFetchingCycles"
          @click="$refs.filtersComponent.redirectToPage('Trace')"
        >
          Trace
          <template #icon>
            <i class="bi bi-box-arrow-up-right mr-2" />
          </template>
        </a-button>
        <!-- <a-button
          v-if="!isNoDataAvailable && !showCompareAnalytics"
          @click="setShowCompareAnalytics(true)"
        >
          Compare with different time range
        </a-button> -->
      </a-space>
    </a-col>
    <a-col span="24">
      <a-divider orientation="left" class="mt-2">
        Dashboard
      </a-divider>

      <!-- <div v-if="showCompareAnalytics" class="d-flex">
        <span
          class="text-center mb-3"
          :class="{
            'w-50': showCompareAnalytics,
            'w-100': !showCompareAnalytics
          }"
        >
          <a-tag>
            {{ getDateRange($refs.filtersComponent.dateRange) }}
          </a-tag>
        </span>
        <span class="text-center mb-3 w-50 ml-auto">
          <a-tag>
            {{ getDateRange(dateRangeForComparison) }}
          </a-tag>
        </span>
      </div> -->

      <div class="d-flex">
        <span
          v-if="isDataNotFound && !isFetchingCycles"
          class="text-center mb-3"
          :class="{
            'w-50': showCompareAnalytics,
            'w-100': !showCompareAnalytics
          }"
        >
          <a-typography :level="4">
            No Data Available
          </a-typography>
        </span>
        <span
          v-if="
            isDataForComparisonNotFound && !isFetchingAnalyticsForComparison
          "
          class="text-center mb-3 w-50 ml-auto"
        >
          <a-typography :level="4">
            No Data Available
          </a-typography>
        </span>
      </div>
    </a-col>

    <!-- Cards -->
    <a-col
      v-for="(card, index) in cards"
      :key="index"
      :xs="card.colXS"
      :sm="card.colSM"
      :md="card.colMD"
      :lg="card.colLG"
      :xl="card.colXL"
    >
      <CardItem :card="card" :card-index="index" />
    </a-col>

    <!-- Charts -->
    <a-col
      v-for="(chart, index) in charts"
      :key="index"
      :xs="chart.colXS"
      :sm="chart.colSM"
      :md="chart.colMD"
      :lg="chart.colLG"
      :xl="chart.colXL"
    >
      <ChartItem v-if="chart.visible" :options="chart" :chart-index="index" />
    </a-col>
  </a-row>
</template>

<script>
import CardItem from '../../../shared/Components/CardItemV3.vue';
import ChartItem from './ChartItemV2.vue';
import { mapActions, mapGetters } from 'vuex';
import Filters from '../../../shared/Components/AnalyticsTraceFilters.vue';
import jsonToCsvDownload from '../../../shared/Helpers/jsonToCsvDownload';
import traceMixin from 'src/mixins/analyticsTrace';
import CycleService from 'src/services/cycles.js';
import VideoService from 'src/services/videos.js';
import RecordedInference from '../../../shared/Components/RecordedInference.vue';
import { ExportOutlined } from '@ant-design/icons-vue';
import TelemetryService from 'src/services/telemetry';
import {
  charts,
  cards,
  screenSizes,
  cardsKeys,
  cardIndicesForFirstDateRangeWithComparison,
  cardIndicesForFirstDateRange,
  cardIndicesForSecondDateRange,
  chartsIndicesForFirstDateRange,
  chartsIndicesForFirstDateRangeWithComparison,
  chartsIndicesForSecondDateRange
} from 'src/config/charts-config.js';
import parseTaskList from 'src/components/shared/Helpers/parseTaskList';
import TaskRecordService from 'src/services/taskRecord';
import { deepClone } from 'src/utils/task';
import { dateTimeFormat } from 'src/config/date-format-config';
import dayjs from 'dayjs';

export default {
  components: {
    RecordedInference,
    ChartItem,
    CardItem,
    Filters,
    ExportOutlined
  },
  mixins: [traceMixin],
  inject: ['toast'],
  provide() {
    return {
      redirectToTrace: this.handleRedirectWithCycleDuration,
      playCycle: this.startPlayingCycle
    };
  },

  data() {
    return {
      chartList: deepClone(charts),
      cards: deepClone(cards),
      analytics_data: {},
      analyticsDataForComparison: {},
      isDataNotFound: false,
      isDataForComparisonNotFound: false,
      filterValues: null,
      filterValuesForComparison: null,
      videoList: [],
      cycle_type: 'all_cycles',
      pagination: {
        pageSize: 6,
        currentPage: 1,
        options: [6, 10, 20, 50]
      },
      filteredVideoList: [],
      cycleWiseMissedSteps: null,
      jsonToCsvDownload,
      showVideoModal: false,
      videoToPlay: null,
      isFetchingCycleDetails: false,
      isFetchingVideoUrl: false,
      videosToPlay: {},
      stepsList: []
    };
  },

  computed: {
    ...mapGetters([
      'organization',
      'devices',
      'stepAvgTime',
      'indexToStepsMapping',
      'deviceSerialNumToDisplayNameMap',
      'stepTime',
      'avgCycleTime',
      'isFetchingCycles',
      'selectedTask',
      'taskDetails',
      'taskName',
      'showCompareAnalytics',
      'isFetchingAnalyticsForComparison',
      'dateRangeForComparison'
    ]),

    charts() {
      const hide = [];
      return this.chartList.filter(chart => !hide.includes(chart.title));
    },

    isNoDataAvailable() {
      return Object.keys(this.analytics_data).length === 0;
    }
  },
  watch: {
    taskDetails(value) {
      this.stepsList = parseTaskList(value);
    },

    showCompareAnalytics(value) {
      if (value) this.handleCompareWithDifferentTimeRange();
      else this.closeCompareWithDifferentTimeRange();
    }
  },
  mounted() {
    this.stepsList = parseTaskList(this.taskDetails);
  },
  methods: {
    ...mapActions([
      'setIsFetchingCycles',
      'setShowCompareAnalytics',
      'setIsFetchingAnalyticsForComparison'
    ]),

    getDateRange(dateRange) {
      const firstDate = `${dayjs(dateRange[0]).format(dateTimeFormat)}`;
      const secondDate = `${`${dayjs(dateRange[1]).format(dateTimeFormat)}`}`;
      return `${firstDate} → ${secondDate}`;
    },

    handleCompareWithDifferentTimeRange() {
      this.cards = this.cards.flatMap((card, index) => [card, cards[index]]);
      this.chartList = this.chartList.flatMap((chart, index) => {
        const newChart = deepClone(charts[index]);
        screenSizes.forEach(screenSize => {
          chart[screenSize] = newChart[screenSize] = 12;
        });
        return [chart, newChart];
      });
    },

    closeCompareWithDifferentTimeRange() {
      this.isDataForComparisonNotFound = false;

      this.cards = deepClone(cards);
      this.chartList = deepClone(charts);
      this.updateCards();
      this.updateCharts();
    },

    handleRedirectWithCycleDuration({
      bin,
      binSize,
      isFilteredOutliers,
      chartIndex
    }) {
      const { minCycleTime, maxCycleTime } = this.getMinMaxCycleTime(
        bin,
        binSize,
        isFilteredOutliers,
        chartIndex
      );
      const query = {
        start_time: Math.floor(minCycleTime),
        end_time: Math.ceil(maxCycleTime)
      };
      console.log('query', query);
      this.$refs.filtersComponent.redirectToPage('Trace', query, chartIndex);
    },

    async handleGetData(filters) {
      if (filters.isComparing) this.getAnalyticsForComparison(filters);
      else this.getAnalytics(filters);
    },

    async getAnalytics(filters) {
      this.setIsFetchingCycles(true);
      const [error, data] = await TelemetryService.fetchCyclesDetailsV2(
        false,
        filters
      );

      this.filterValues = filters;
      this.setIsFetchingCycles(false);
      if ((data?.body && data?.body == 'No result found') || error) {
        this.clearCharts();
        this.clearCards();
        this.analytics_data = {};
        this.isDataNotFound = true;
        return;
      }
      this.isDataNotFound = false;

      this.analytics_data = data;
      this.updateCards();
      this.updateCharts();
    },

    async getAnalyticsForComparison(filters) {
      this.setIsFetchingAnalyticsForComparison(true);
      const [error, data] = await TelemetryService.fetchCyclesDetailsV2(
        false,
        filters
      );

      this.filterValuesForComparison = filters;

      if ((data?.body && data?.body == 'No result found') || error) {
        this.clearCards(true);
        this.clearCharts(false);
        this.analyticsDataForComparison = {};
        this.isDataForComparisonNotFound = true;
        this.setIsFetchingAnalyticsForComparison(false);
        return;
      }
      this.isDataForComparisonNotFound = false;

      this.analyticsDataForComparison = data;
      this.updateCards(true);
      this.updateCharts(true);
      this.setIsFetchingAnalyticsForComparison(false);
    },

    fetchCycleWiseMissedSteps() {
      if (this.cycleWiseMissedSteps) return;
      return new Promise(async resolve => {
        const queryParams = {
          organization: this.organization,
          devices: this.filterValues.devices,
          task: this.filterValues.task,
          datetime_start: this.filterValues.datetime_start,
          datetime_end: this.filterValues.datetime_end
        };
        const [error, data] = await CycleService.fetchCycleWiseMissedStepsV2(
          true,
          queryParams
        );
        if (error) {
          console.log({ error });
          this.cycleWiseMissedSteps = null;
          resolve();
        }
        this.cycleWiseMissedSteps = data;
        resolve();
      });
    },

    async exportCyclesCSV() {
      if (this.analytics_data) {
        await this.fetchCycleWiseMissedSteps();
        const dataToExport = this?.cycleWiseMissedSteps?.map(cycle => ({
          ...cycle,
          'Device Name': this.deviceSerialNumToDisplayNameMap[cycle['Device']],
          'Missed Steps': this.getMissedSteps(cycle['Missed Steps'])
        }));
        const { task, datetime_start, datetime_end } = this.filterValues;
        const start_date = datetime_start.split(' ')[0];
        const end_date = datetime_end.split(' ')[0];
        jsonToCsvDownload(dataToExport, `${task}_${start_date}_${end_date}`);
      } else this.toast.info('No Data Found!');
    },

    getMissedSteps(missedStepsIndex) {
      if (!missedStepsIndex) return '';
      return missedStepsIndex
        .split(', ')
        .map(stepIndex => this.indexToStepsMapping[stepIndex])
        .join('; ')
        .replaceAll(',', '&');
    },

    getMinMaxCycleTime(bin, binSize, isFilteredOutliers, chartIndex) {
      let key = 'analytics_data';
      if (this.showCompareAnalytics) {
        const isChartIndexInSecondDateRange = chartsIndicesForSecondDateRange.includes(
          chartIndex
        );
        key = isChartIndexInSecondDateRange
          ? 'analyticsDataForComparison'
          : key;
      }

      const {
        bins_of_cycle_times,
        bins_of_cycle_times_without_outliers
      } = this[key]['cycle_time_distribution'][binSize];

      const cyclesTimes = isFilteredOutliers
        ? bins_of_cycle_times_without_outliers
        : bins_of_cycle_times;

      const arr = cyclesTimes[bin]?.sort((a, b) => a - b);
      if (arr.length === 1) {
        return {
          minCycleTime: arr[0] - 1,
          maxCycleTime: arr[0] + 1
        };
      } else {
        return {
          minCycleTime: arr[0],
          maxCycleTime: arr[arr.length - 1]
        };
      }
    },

    updateCards(isComparing = false) {
      const cards = [...this.cards];
      const analyticsDataKey = isComparing
        ? 'analyticsDataForComparison'
        : 'analytics_data';

      let cardsIndices = [];

      if (isComparing) {
        cardsIndices = cardIndicesForSecondDateRange;
      } else {
        cardsIndices = this.showCompareAnalytics
          ? cardIndicesForFirstDateRangeWithComparison
          : cardIndicesForFirstDateRange;
      }
      this.updateCardsAccToIndices(cardsIndices, cards, this[analyticsDataKey]);

      this.cards = cards;
    },

    updateCardsAccToIndices(cardsIndices, cards, data) {
      const { cycles_with_errors, cycles_without_errors } = data;
      cardsKeys.forEach((cardKey, index) => {
        cards[cardsIndices[index]] = {
          ...cards[cardsIndices[index]],
          value: data[cardKey]
        };
        if (index === 0) {
          cards[cardsIndices[index]] = {
            ...cards[cardsIndices[index]],
            goodCycles: cycles_without_errors,
            badCycles: cycles_with_errors
          };
        }
      });
    },

    clearCards(isComparing = false) {
      const cards = [...this.cards];
      let cardsIndices = [];
      if (isComparing) {
        cardsIndices = cardIndicesForSecondDateRange;
      } else {
        cardsIndices = this.showCompareAnalytics
          ? cardIndicesForFirstDateRangeWithComparison
          : cardIndicesForFirstDateRange;
      }
      cardsKeys.forEach((_, index) => {
        cards[cardsIndices[index]] = {
          ...cards[cardsIndices[index]],
          value: 0
        };
        if (index === 0) {
          cards[cardsIndices[index]] = {
            ...cards[cardsIndices[index]],
            goodCycles: 0,
            badCycles: 0
          };
        }
      });
      this.cards = cards;
    },

    async updateCharts(isComparing = false) {
      const charts = [...this.chartList];
      const chartsHandlers = [
        this.updateStepAvgTimeChart,
        this.updateCycleTimeDistributionChart,
        this.updateMissedStepsChart,
        this.updateNonRelatedActivityChart,
        this.updateTimeDistributionChart,
        this.updateSessionGraph,
        this.updateTimeLineGraphData
      ];
      const analyticsDataKey = isComparing
        ? 'analyticsDataForComparison'
        : 'analytics_data';
      let chartsIndices = [];
      if (isComparing) {
        chartsIndices = chartsIndicesForSecondDateRange;
      } else {
        chartsIndices = this.showCompareAnalytics
          ? chartsIndicesForFirstDateRangeWithComparison
          : chartsIndicesForFirstDateRange;
      }

      chartsHandlers.forEach((chartHandler, index) => {
        chartHandler(chartsIndices[index], charts, this[analyticsDataKey]);
      });

      this.chartList = charts;
    },

    clearCharts(isComparing = false) {
      const chartList = [...this.chartList];
      for (let chart in chartList) {
        chartList[chart].data = '';
        chartList[chart].labels = '';
        chartList[chart].stepsAvgTime = '';
        chartList[chart].stepsStdDev = '';
      }
      this.chartList = chartList;
    },

    updateStepAvgTimeChart(chartIndex, charts, analyticsData) {
      const { average_substep_times, sub_steps_time_std_dev } = analyticsData;

      let data = [];
      const labels = Object.keys(average_substep_times);
      const stepsAvgTime = [];
      const stepsStdDev = [];

      labels.forEach(step => {
        const mean = average_substep_times[step];
        const stepExpectedTime = this.stepAvgTime(step.trim());

        stepsAvgTime.push([stepExpectedTime, stepExpectedTime]);

        if (sub_steps_time_std_dev) {
          const std = sub_steps_time_std_dev[step];
          const stdPoint = mean - std < 0 ? 0 : mean - std;
          stepsStdDev.push([stdPoint, mean + std]);
          data.push({
            y: mean,
            custom: { std, stepExpectedTime, mean }
          });
        } else data.push(mean);
      });

      charts[chartIndex]['data'] = data;
      charts[chartIndex]['labels'] = labels;
      charts[chartIndex]['stepsAvgTime'] = stepsAvgTime;
      charts[chartIndex]['stepsStdDev'] = stepsStdDev;
    },

    updateCycleTimeDistributionChart(chartIndex, charts, analyticsData) {
      const { cycle_time_distribution } = analyticsData;
      charts[chartIndex]['data'] = Object.entries(
        cycle_time_distribution
      ).reduce((res, [key, value]) => {
        const {
          bins_of_cycle_times,
          bins_of_cycle_times_without_outliers
        } = value;
        res[key] = {
          bins_of_cycle_times: this.getFreqAndBins(bins_of_cycle_times),
          bins_of_cycle_times_without_outliers: this.getFreqAndBins(
            bins_of_cycle_times_without_outliers
          )
        };
        return res;
      }, {});
      charts[chartIndex]['plotLine'] = this.avgCycleTime;
    },

    getFreqAndBins(binsOfCycleTimes) {
      const freq = [];
      const bins = [];
      Object.entries(binsOfCycleTimes).forEach(([bin, cycles]) => {
        bins.push(bin);
        freq.push(cycles.length);
      });
      return {
        freq,
        bins
      };
    },

    updateMissedStepsChart(chartIndex, charts, analyticsData) {
      const filteredMissedSteps = this.filterNegStepsFromMissedSteps(
        analyticsData.missed_steps
      );

      charts[chartIndex]['data'] = [...Object.values(filteredMissedSteps)];
      charts[chartIndex]['labels'] = [...Object.keys(filteredMissedSteps)];
    },

    updateNonRelatedActivityChart(chartIndex, charts, analyticsData) {
      const { sessions_per_day, labels } = analyticsData.session_chart;
      const chartData = this.getSessionNonRelatedActivityData(
        sessions_per_day,
        labels
      );

      charts[chartIndex]['data'] = chartData;
      charts[chartIndex]['labels'] = labels;
    },

    updateTimeDistributionChart(chartIndex, charts, analyticsData) {
      charts[chartIndex]['data'] = Object.values(analyticsData.line_chart).map(
        e => e.cycle_time
      );
      charts[chartIndex]['labels'] = Object.keys(analyticsData.line_chart);
    },

    updateSessionGraph(chartIndex, charts, analyticsData) {
      const { sessions_per_day, labels } = analyticsData.session_chart;
      const chartData = this.getSessionChartData(sessions_per_day, labels);
      charts[chartIndex]['data'] = chartData;
      charts[chartIndex]['labels'] = labels;
    },

    getSessionChartData(temp, labels) {
      temp = deepClone(temp);
      let maxSessionCount = Math.max(...Object.values(temp).map(r => r.length));
      const chartData = [];
      for (let i = 0; i < maxSessionCount; i++) {
        let verticalBar = {
          type: 'errorbar',
          data: []
        };
        chartData.push({
          type: 'bar',
          data: labels.map(d => {
            const y = temp[d]?.shift();
            if (!y) {
              verticalBar.data.push([]);
              return null;
            }
            const { bar, line } = this.getSessionChartPointData(y);
            verticalBar.data.push(line);
            return bar;
          })
        });
        chartData.push(verticalBar);
      }
      return chartData;
    },

    getSessionChartPointData(y) {
      let totalCycles = null;
      if (this.avgCycleTime) {
        totalCycles = Math.round(y['sessionDur'] / this.avgCycleTime);
      }
      return {
        bar: {
          startTime: y['startTime'],
          endTime: y['endTime'],
          y: y['cycleCount'],
          totalCycles: totalCycles,
          sessionDur: y['sessionDur'],
          color: '#8AC3F6'
        },
        line: totalCycles ? [0, totalCycles] : []
      };
    },

    getSessionNonRelatedActivityData(temp, labels) {
      temp = deepClone(temp);
      let maxSessionCount = Math.max(...Object.values(temp).map(r => r.length));
      const chartData = [];
      for (let i = 0; i < maxSessionCount; i++) {
        chartData.push({
          type: 'bar',
          data: labels.map(d => {
            const y = temp[d]?.shift();
            // const y = temp[d][i];
            if (!y) return null;

            const bar = this.getSessionNonRelatedActivityValue(y);

            return bar;
          })
        });
      }
      return chartData;
    },

    getSessionNonRelatedActivityValue(y) {
      return {
        startTime: y['startTime'],
        endTime: y['endTime'],
        y: y['avg_background_time'],
        sessionDur: y['sessionDur'],
        color: '#8AC3F6'
      };
    },

    updateTimeLineGraphData(chartIndex, charts, analyticsData) {
      charts[chartIndex].data = Object.entries(analyticsData.line_chart).reduce(
        (res, [key, val]) => {
          res[key] = {
            ...val,
            device_id: this.deviceSerialNumToDisplayNameMap[val.device_id]
          };
          return res;
        },
        {}
      );
    },

    async startPlayingCycle(cycleIdentifier) {
      this.showVideoModal = true;
      this.isFetchingCycleDetails = true;
      if (this.isVideoExistsInState(cycleIdentifier)) return;
      this.isFetchingVideoUrl = true;

      const [, data] = await this.getCycleDetails(cycleIdentifier);

      if (data.fileName) {
        const [video_url, preds_url] = await Promise.all([
          this.getVideoUrl(data),
          this.getPredictionFileUrl(data)
        ]);

        if (!!data.entity_id && !!data.no_of_comments) {
          const comment_response = await VideoService.getCommentsForEntity(
            data.entity_id
          );
          data['comments'] = comment_response;
        } else {
          data['comments'];
        }

        const videoFPS = await this.getVideoFPS(video_url);

        this.isFetchingCycleDetails = false;
        this.isFetchingVideoUrl = false;

        if ([video_url, preds_url].includes('error')) {
          this.toast.error('Error occure while fetching url!');
          return;
        }
        this.setVideoToPlay({ ...data, fps: videoFPS }, video_url, preds_url);
      } else {
        this.setVideoToPlay(data);
        this.isFetchingCycleDetails = false;
        this.isFetchingVideoUrl = false;
      }
    },

    isVideoExistsInState(cycleIdentifier) {
      if (this.videosToPlay[cycleIdentifier]) {
        this.videoToPlay = this.videosToPlay[cycleIdentifier];
        this.isFetchingCycleDetails = false;
        return true;
      }
      return false;
    },

    getCycleDetails(cycleIdentifier) {
      const deviceId = this.analytics_data.line_chart[cycleIdentifier]
        .device_id;
      return new Promise(async resolve => {
        const [error, data] = await CycleService.fetchCycleDetailsV2(
          false,
          cycleIdentifier,
          deviceId
        );
        data['missed_steps'] = data?.missed_steps?.join(', ') ?? '';
        resolve([error, data]);
      });
    },

    async getVideoUrl(cycleDetails) {
      if (!cycleDetails?.id) return new Error('Video does not exist!');
      const { fileName, device_id } = cycleDetails;
      const payload = {
        bucket_name: `${this.organization}-videos`,
        object_path: `${device_id}/${this.selectedTask}/Processed/${fileName}`
      };
      const [error, data] = await VideoService.getPresignedUrl(payload);
      return error || data?.presigned_url;
    },

    async getPredictionFileUrl(video) {
      const { device_id } = video;
      const fileName = video.fileName + '_preds.json';
      let filePath = `${device_id}/${this.selectedTask}/Processed/${fileName}`;
      const payload = {
        bucket_name: `${this.organization}-videos`,
        object_path: filePath
      };
      const [error, data] = await VideoService.getPresignedUrl(payload);
      return error || data?.presigned_url;
    },

    getVideoFPS(video_url) {
      return new Promise(async resolve => {
        let payload = { video_path: video_url };
        const [error, data] = await TaskRecordService.getVideoMetaData(
          payload,
          false
        );
        if (error) {
          console.log({ error });
          return resolve(30);
        }
        const { streams } = data;
        let { avg_frame_rate } = streams[0];
        return resolve(Number(eval(avg_frame_rate)).toFixed(2));
      });
    },

    setVideoToPlay(videoDetails, fileURL, preds_url) {
      if (!videoDetails) return;
      const { cycle_identifier } = videoDetails;
      this.videoToPlay = this.appendMissedSteps({
        ...videoDetails,
        fileURL,
        per_frame_prediction_file_url: preds_url
      });

      this.videoToPlay = {
        ...this.videoToPlay,
        isVideoExists: !!fileURL,
        fileName: videoDetails.fileName || videoDetails.cycle_identifier
      };

      this.videosToPlay[cycle_identifier] = { ...this.videoToPlay };
    },

    updateVideoRecord({ videoName, updatedObj }) {
      const cycleIdentifier = videoName?.split('.')[0];
      if (!this.videosToPlay[cycleIdentifier]) return;

      this.videosToPlay[cycleIdentifier] = this.videosToPlay = {
        ...this.videosToPlay,
        [cycleIdentifier]: {
          ...this.videosToPlay[cycleIdentifier],
          ...updatedObj
        }
      };
    },

    handleCloseVideoModal() {
      this.showVideoModal = false;
      this.videoToPlay = null;
    }
  }
};
</script>
