<template>
  <a-card v-if="isLoading" :body-style="{ height: '60vh', display: 'flex' }">
    <a-spin class="m-auto" size="large" />
  </a-card>
  <a-card
    v-else
    hoverable
    :title="options.title"
    :body-style="{ padding: '1px 0' }"
    class="chart-card"
  >
    <template #extra>
      <a-space v-if="chartsNames.CycleTimeDistribution === options.title">
        <a-typography small>
          Bin width:
        </a-typography>
        <a-button
          v-for="option in binsOption"
          :key="option"
          size="small"
          :type="selectedBinSize === option ? 'primary' : 'default'"
          @click="setBinSize(option)"
        >
          {{ option }}
        </a-button>
      </a-space>

      <a-button
        v-if="chartsNames.TimeDistribution === options.title"
        @click="isFilteredOutliers = !isFilteredOutliers"
      >
        {{ isFilteredOutliers ? 'Reset Filter' : 'Filter Outliers' }}
      </a-button>

      <a-tooltip :title="options.description">
        <QuestionOutlined class="info-icon" />
      </a-tooltip>
    </template>

    <StepAverageTime
      v-if="chartsNames.StepAverageTime === options.title"
      :options="options"
    />

    <CycleTimeDistribution
      v-if="chartsNames.CycleTimeDistribution === options.title"
      ref="cycleTimeDistribution"
      :selected-bin-size="selectedBinSize"
      :options="options"
      :chart-index="chartIndex"
    />

    <MissedSteps
      v-if="chartsNames.MissedSteps === options.title"
      :options="options"
    />

    <NonRelatedActivity
      v-if="chartsNames.NonRelatedActivity === options.title"
      :options="options"
    />

    <TimeDistribution
      v-if="chartsNames.TimeDistribution === options.title"
      :options="options"
      :is-filtered-outliers="isFilteredOutliers"
    />

    <TimelineChart
      v-if="chartsNames.DeviceWiseCycleTimeDistribution === options.title"
      :options="options"
    />

    <DateWiseSessions
      v-if="chartsNames.DateWiseSessions === options.title"
      :options="options"
    />

    <!-- <SunburstChart
      v-if="chartsNames.PerDaySessions === options.title"
      :options="options"
    /> -->
  </a-card>
</template>

<script>
import { mapGetters } from 'vuex';
import StepAverageTime from './Charts/StepAverageTime.vue';
import CycleTimeDistribution from './Charts/CycleTimeDistribution.vue';
import MissedSteps from './Charts/MissedSteps.vue';
import NonRelatedActivity from './Charts/NonRelatedActivity.vue';
import TimeDistribution from './Charts/TimeDistribution.vue';
import TimelineChart from './Charts/TimelineChart.vue';
import DateWiseSessions from './Charts/DateWiseSessions.vue';
import { chartsNames } from 'src/config/charts-config.js';
import { QuestionOutlined } from '@ant-design/icons-vue';
import { ref } from 'vue';
import {
  chartsIndicesForSecondDateRange,
  chartsIndicesForFirstDateRangeWithComparison,
} from 'src/config/charts-config.js';

export default {
  components: {
    StepAverageTime,
    CycleTimeDistribution,
    MissedSteps,
    NonRelatedActivity,
    TimeDistribution,
    TimelineChart,
    DateWiseSessions,
    QuestionOutlined,
  },

  props: {
    options: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    chartIndex: {
      type: Number,
      default: -1,
      required: true,
    },
  },

  emits: ['showCycles'],

  setup: () => {
    const cycleTimeDistribution = ref(null);
    const selectedBinSize = ref(20);
    const binsOption = [5, 10, 20, 30, 40];
    const isFilteredOutliers = ref(false);

    const setBinSize = (binSize) => {
      selectedBinSize.value = binSize;
      cycleTimeDistribution.value.setChartOptions(binSize);
    };

    return {
      chartsNames,
      selectedBinSize,
      binsOption,
      isFilteredOutliers,
      cycleTimeDistribution,
      setBinSize,
    };
  },

  computed: {
    ...mapGetters([
      'isFetchingCycles',
      'showCompareAnalytics',
      'isFetchingAnalyticsForComparison',
    ]),
    isLoading() {
      const {
        showCompareAnalytics,
        isFetchingCycles,
        chartIndex,
        isFetchingAnalyticsForComparison,
      } = this;
      if (showCompareAnalytics) {
        if (chartsIndicesForFirstDateRangeWithComparison.includes(chartIndex))
          return isFetchingCycles;
        else if (chartsIndicesForSecondDateRange.includes(chartIndex))
          return isFetchingAnalyticsForComparison;
      } else {
        return isFetchingCycles;
      }
      return false;
    },
  },

  methods: {
    showChart(chartName) {
      return this.chartsNames[chartName] === this.options.title;
    },
  },
};
</script>
<style>
.chart-card > .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title {
  text-align: v-bind('options.alignTitle');
}

.info-icon {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.4);
  font-size: 1em !important;
  margin-left: 2em;
}
</style>
