<template>
  <div
    v-if="loading"
    :style="`height: ${showCompareAnalytics ? 50 : 75}vh`"
    class="d-flex"
  >
    <a-spin class="m-auto" />
  </div>
  <vue-highcharts
    v-if="chartOptions && !loading"
    ref="chart"
    type="chart"
    :options="chartOptions"
  />
</template>
<script>
import VueHighcharts from 'vue3-highcharts';
import Highcharts from 'highcharts';
import exporting from 'highcharts/modules/exporting';
import exportData from 'highcharts/modules/export-data';
import accessibility from 'highcharts/modules/accessibility';
import { deepClone } from 'src/utils/task';
import { mapGetters } from 'vuex';

exporting(Highcharts);
exportData(Highcharts);
accessibility(Highcharts);

export default {
  components: { VueHighcharts },
  inject: ['playCycle'],
  props: {
    options: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    isFilteredOutliers: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  data() {
    return {
      chartOptions: null,
      filteredData: [],
      outLierLimit: 600,
      loading: false,
    };
  },

  computed: { ...mapGetters(['showCompareAnalytics']) },

  watch: {
    isFilteredOutliers() {
      this.loading = true;
      const { data } = this.options;
      const tempOptions = deepClone(this.chartOptions);
      const filteredData = this.isFilteredOutliers ? this.filteredData : data;
      const self = this;
      tempOptions.series[0]['data'] = filteredData;
      tempOptions.series[0]['point']['events']['click'] = function() {
        const { category: cycleIdentifier } = this;
        self.playCycle(cycleIdentifier);
      };
      this.chartOptions = tempOptions;
      setTimeout(() => {
        this.loading = false;
      }, 10);
    },

    chartOptions() {
      const { data } = this.options;
      if (!data) return;
      this.filteredData = this.options.data.filter(
        (el) => el < this.outLierLimit
      );
    },
  },

  mounted() {
    this.setChartOptions();
  },

  methods: {
    setChartOptions() {
      const self = this;
      const { data, labels, xlabelString, ylabelString } = this.options;
      this.chartOptions = {
        chart: {
          zoomType: 'xy',
          height: this.showCompareAnalytics ? '70%' : '50%',
          scrollablePlotArea: {
            opacity: 1,
            minWidth: data.length * 20,
            scrollPositionX: 0,
          },
        },
        credits: {
          enabled: false,
        },
        title: {
          text: '', //title,
        },
        legend: {
          enabled: false,
        },
        yAxis: {
          title: {
            text: ylabelString,
          },
        },

        xAxis: {
          categories: labels,
          title: {
            text: xlabelString,
          },
        },
        plotOptions: {
          series: {
            states: {
              hover: {
                enabled: true,
                lineWidth: 0.5,
              },
            },
          },
        },

        tooltip: {
          formatter: function() {
            const { x, y } = this;

            return `<span>Date: </span><b>${x}</b>
                <br/><span>Cycle Time: </span><b>${y}s</b>`;
          },
        },
        series: [
          {
            name: '',
            type: 'line',
            data: data,
            lineWidth: 0.8,
            point: {
              events: {
                click: function() {
                  const { category: cycleIdentifier } = this;
                  self.playCycle(cycleIdentifier);
                },
              },
            },
          },
        ],
      };
    },
  },
};
</script>
