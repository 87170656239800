<template>
  <!-- <a-button
    v-if="!isNoDataAvailable"
    class="ml-auto"
    :disabled="isFetchingCycles"
    @click="compareAnalytics"
  >
    {{ showCompareAnalytics ? 'Close Compare Analytics' : 'Compare Analytics' }}
    <template #icon>
      <export-outlined />
    </template>
  </a-button> -->
  <a-row class="p-4">
    <a-col span="24">
      <analytics-dashboard />
    </a-col>
    <!-- <a-col span="1" class="text-center"> -->
    <!-- <a-divider type="vertical" class="h-100" /> -->
    <!-- </a-col> -->
    <!-- <a-col span="12">
      <analytics-dashboard />
    </a-col> -->
  </a-row>
  <!-- <div :class="{ row: showCompareAnalytics }">
    <div :class="{ column: showCompareAnalytics }">
      <analytics-dashboard />
    </div>
    <div :class="{ column: showCompareAnalytics }">
      <analytics-dashboard />
    </div>
  </div> -->
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AnalyticsDashboard from './AnalyticsDashboard.vue';

export default {
  components: {
    AnalyticsDashboard,
  },

  computed: {
    ...mapGetters(['showCompareAnalytics']),
  },
  methods: {
    ...mapActions(['setShowCompareAnalytics']),

    compareAnalytics() {
      this.setShowCompareAnalytics(!this.showCompareAnalytics);
    },
  },
};
</script>

<style>
.column {
  float: left;
  width: 50%;
}

/* Clear floats after the columns */
.row:after {
  content: '';
  display: table;
  clear: both;
}
</style>
