<template>
  <vue-highcharts v-if="chartOptions" type="chart" :options="chartOptions" />
</template>
<script>
import VueHighcharts from 'vue3-highcharts';
import Highcharts from './HighCharts';
import xrange from 'highcharts/modules/xrange.js';
import { mapGetters } from 'vuex';

xrange(Highcharts);

export default {
  name: 'GroupedStackedBarChart',
  components: {
    VueHighcharts,
  },
  props: {
    options: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  data() {
    return {
      devices: {},
      dates: [],
      SEC_IN_24_HOURS: 86400,
      SEC_IN_HOUR: 3600,
      chartOptions: null,
    };
  },
  computed: { ...mapGetters(['showCompareAnalytics']) },

  async mounted() {
    this.setChartOptions();
  },
  methods: {
    setChartOptions() {
      const { getXAxisConfig, getChartHeight, getChartData, options } = this;
      const { data, ylabelString } = options;
      const chartData = getChartData(data);
      const chartHeight = getChartHeight();
      this.chartOptions = {
        chart: {
          zoomType: 'xy',
          type: 'xrange',
          height: this.showCompareAnalytics ? '70%' : '50%',

          scrollablePlotArea: {
            opacity: 1,
            minHeight: chartHeight,
            scrollPositionY: 0,
          },
        },
        credits: {
          enabled: false,
        },
        title: {
          text: '', //title,
        },
        xAxis: [
          { ...getXAxisConfig() },
          { ...getXAxisConfig(false), opposite: true },
        ],
        yAxis: {
          title: {
            text: ylabelString,
          },
          categories: this.dates,
          reversed: true,
        },
        tooltip: {
          formatter: function() {
            const {
              cycle_start_time,
              cycle_finished_time,
              cycle_time,
              device,
              date,
            } = this.point.custom;

            return `<span>Device Id: </span><b>${device}</b>
                <br/><span>Cycle Time: </span><b>${cycle_time}s</b>
                <br/><span>Cycle Start Time: </span><b>${cycle_start_time}</b>
                <br/><span>Cycle Finish Time: </span><b>${cycle_finished_time}</b>
                <br/><span>Date: </span><b>${date}</b>`;
          },
        },
        legend: {
          verticalAlign: 'top',
          borderWidth: 1,
          itemStyle: {
            fontWeight: 'normal',
            fontSize: '10px',
          },
          borderColor: '#e4e4e4',
          backgroundColor: '#f1f1f1',
        },
        plotOptions: {
          series: {
            turboThreshold: 10000,
          },
        },
        series: chartData,
      };
    },

    getChartData(data) {
      Object.entries(data).forEach(([key, value]) => {
        const [date, time] = key.split(' ');
        const { device_id, cycle_time } = value;

        if (!this.dates.includes(date)) this.dates.push(date);
        if (!this.devices[device_id]) this.setDeviceState(device_id);

        const obj = this.getPointData(time, cycle_time, date, device_id, key);

        this.devices[device_id]['data'].push(obj);
      });

      return Object.values(this.devices);
    },

    setDeviceState(device_id) {
      this.devices[device_id] = {
        name: device_id,
        borderWidth: 0,
        pointWidth: 20,
        borderRadius: 2,
        groupPadding: 0,
        color: this.getColor(device_id),
        showInLegend: true,
        data: [],
      };
    },

    getPointData(time, cycle_time, date, device_id, key) {
      return {
        ...this.getRange(time, cycle_time),
        y: this.dates.indexOf(date),
        color: this.getColor(device_id),
        custom: {
          cycle_time: cycle_time,
          device: device_id,
          cycle_start_time: this.getCycleStartTime(key, cycle_time),
          cycle_finished_time: time,
          date,
        },
      };
    },

    getRange(time, cycle_time) {
      return {
        x: this.getWhiteArea(time, cycle_time),
        x2: this.getWhiteArea(time),
      };
    },

    getWhiteArea(time, seconds = 0) {
      const [h, m, s] = time.split(':').map((e) => Number(e));
      return (((h * 60 + m) * 60 + s - seconds) / this.SEC_IN_24_HOURS) * 24;
    },

    getChartHeight() {
      const numOfDevices = Object.keys(this.devices).length;
      const numOfDates = this.dates.length;
      const factor = 30 + numOfDevices * 20;
      return factor * (numOfDates + numOfDevices) < 400
        ? null
        : factor * (numOfDates + numOfDevices);
    },

    getXAxisConfig() {
      const { getTimeFromSeconds } = this;
      return {
        type: 'time',
        title: {
          text: this.options.xlabelString,
        },
        min: 0,
        max: 24,
        labels: {
          rotation: 45,
          formatter: function() {
            return getTimeFromSeconds(this.value);
          },
        },
      };
    },

    getTimeFromSeconds(value) {
      return new Date(value * 1000 * this.SEC_IN_HOUR)
        .toISOString()
        .substring(11, 19);
    },

    getColor(device_id) {
      const devices = Object.keys(this.devices);
      const deviceIndex =
        devices.indexOf(device_id) === -1
          ? devices.length
          : devices.indexOf(device_id);

      const color = Highcharts.getOptions().colors[deviceIndex];

      return color;
    },

    getCycleStartTime(datetime, cycleTime) {
      return new Date(new Date(datetime).getTime() - cycleTime * 1000)
        .toString()
        .substr(16, 8);
    },
  },
};
</script>
