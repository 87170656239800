<template>
  <vue-highcharts v-if="chartOptions" type="chart" :options="chartOptions" />
</template>
<script>
import VueHighcharts from 'vue3-highcharts';
import Highcharts from './HighCharts';
import highchartsMore from 'highcharts/highcharts-more';
import exporting from 'highcharts/modules/exporting';
import exportData from 'highcharts/modules/export-data';
import accessibility from 'highcharts/modules/accessibility';
import { mapGetters } from 'vuex';

highchartsMore(Highcharts);
exporting(Highcharts);
exportData(Highcharts);
accessibility(Highcharts);

export default {
  components: { VueHighcharts },
  props: {
    options: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  data() {
    return {
      chartOptions: null,
    };
  },

  computed: { ...mapGetters(['showCompareAnalytics']) },

  mounted() {
    this.setChartOptions();
  },
  methods: {
    setChartOptions() {
      const { data, labels, xlabelString, ylabelString } = this.options;
      this.chartOptions = {
        chart: {
          zoomType: 'xy',
          height: this.showCompareAnalytics ? '70%' : '50%',
          scrollablePlotArea: {
            minHeight: labels.length * 100,
            scrollPositionY: 0,
          },
        },
        title: {
          text: '', //title,
        },
        xAxis: {
          categories: labels,
          crosshair: true,
          title: {
            text: xlabelString,
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: ylabelString,
          },
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          formatter() {
            return this.point.startTime
              ? `</br><span>Start Time:</span> <b>${this.point.startTime}</b>
                    </br><span>End Time:</span> <b>${this.point.endTime}</b>
                  </br><span>Cycles Count:</span> <b>${this.point.y}</b>
                  ${
                    this.point.totalCycles
                      ? `</br><span>Expected Cycles:</span> <b>${this.point.totalCycles}</b>`
                      : ''
                  }
                `
              : false;
          },
        },
        plotOptions: {
          bar: {
            pointPadding: 0.6,
            borderWidth: 1,
            dataLabels: {
              enabled: true,
            },
          },
          series: {
            pointWidth: 12,
          },
          errorbar: {
            color: '#434348',
            stemWidth: 0,
            whiskerLength: 10,
            tooltip: false,
          },
        },
        series: data,
      };
    },
  },
};
</script>
<style></style>
